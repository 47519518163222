import styles from "./LogoTps.module.css";
import Code from "./Code";

const LogoTps = () => {
  return (
    <div className={styles.wrapper}>
      <img src="/images/logoTps.svg" className={styles.logo} alt="Tps Game logo" />
      <Code />
    </div>
  );
};
export default LogoTps;

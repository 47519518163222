import React from "react";
import styles from "./Code.module.css";

class Code extends React.Component {
  constructor() {
    super();
    this.readiness = 0;
    this.numberOfAwaitedOperations = 2;
    this.script = "";
    this.fetchScripts();
  }

  componentDidMount() {
    this.startLoadedScripts();
  }

  async fetchScripts() {
    const source = await fetch("../../tooltip/controller.js");
    const script = await source.text();
    this.script = script;
    this.startLoadedScripts();
  }

  startLoadedScripts() {
    this.readiness++;
    if (this.readiness === this.numberOfAwaitedOperations) {
      const scriptEl = document.createElement("script");
      const firstScriptEl = document.getElementsByTagName("script")[0];
      scriptEl.textContent = this.script;
      scriptEl.type = "text/javascript";
      firstScriptEl.parentNode.insertBefore(scriptEl, firstScriptEl);
    }
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div
          className="tooltip_container"
          title="1) Подключитесь к каналу Discord по ссылке https://discord.gg/tTeVYy6fB7;
2) Передайте текст кода боту Старпом;
3) Следуйте дальнейшим инструкциям бота."
        >
          <p className={styles.code}>{"{tps_m8SumTQULd}"}</p>
        </div>
      </div>
    );
  }
}

export default Code;
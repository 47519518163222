import styles from "./Main.module.css";
import Timer from "./Timer";

const Main = () => {
  return (
    <main className={styles.wrapper}>
        <p className={styles.text}>
            Квест подошел к концу.
        </p>
        <p className={styles.info}>
            Произошло немало интересного! Читай прохождение квеста{" "}
            <a className={styles.link} href="/endpoints/longread.pdf" target="_blank" rel="noreferrer">
                тут
            </a>
            , чтобы узнать, что же случилось в час собаки!
        </p>
        <p className={styles.info}>
            А также заходи в{" "}
            <a className={styles.link} href="https://discord.gg/tTeVYy6fB7" target="_blank" rel="noreferrer">
                Discord
            </a>
            , где ты сможешь следить за новостями о следующем приключении, которое мы уже начали готовить!
        </p>
    </main>
  );
};
// <p className={styles.info}>
//   Часть первая
// </p>
// <Timer />

export default Main;

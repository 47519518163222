import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.wrapper}>
      <div className={styles.border}></div>
      <div className={styles.content}>
        <a href="/rules/" className={styles.item + " " + styles.rules} target="_blank" rel="noreferrer">
          Правила
        </a>
        <a
          href="https://discord.gg/tTeVYy6fB7"
          className={styles.item + " " + styles.discord}
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/logoDiscord.svg" alt="Discord logo" className={styles.logo} />
        </a>
        <p className={styles.item + " " + styles.copyright}>Сделано по заказу ОАО «Кондитерский концерн Бабаевский»</p>
      </div>
    </footer>
  );
};
export default Footer;

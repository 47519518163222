import React from "react";
import styles from "./Timer.module.css";
import { getRemaining } from "../../services";
class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      remaining: 0
    };

    this.getRemainigTime = (remainigSeconds) => {
      const days = Math.floor(remainigSeconds / 86400);
      const hours = Math.floor((remainigSeconds % 86400) / 3600);
      const minutes = Math.floor(((remainigSeconds % 86400) % 3600) / 60);
      const seconds = Math.floor(((remainigSeconds % 86400) % 3600) % 60);
      return [days, hours, minutes, seconds];
    };
  }
  componentDidMount() {
    getRemaining()
      .then((res) => res.text())
      .then((data) => {
        const remaining = Number(data) / 1000;
        this.setState({ remaining, isLoading: false });
        this.timerId = setInterval(() => {
          const remaining = this.state.remaining - 1;
          if (remaining > 0) {
            this.setState({ remaining: this.state.remaining - 1 });
          } else {
            clearInterval(this.timerId);
            window.location.reload();
          }
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }
  render() {
    let timeParts = this.getRemainigTime(this.state.remaining);
    return (
      <div className={styles.time}>
        {!this.state.isLoading &&
          timeParts.map((part, idx) => {
            return (
              <React.Fragment key={"key" + idx}>
                <span className={styles.timepart}>{part.toString().padStart(2, "0")}</span>
                {idx < timeParts.length - 1 && <span className={styles.separator}> : </span>}
              </React.Fragment>
            );
          })}
      </div>
    );
  }
}
export default Timer;

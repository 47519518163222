import styles from "./LogoWoW.module.css";

const LogoWoW = () => {
  return (
    <div className={styles.wrapper}>
      <a href="https://worldofwarships.ru/" className={styles.link} target="_blank" rel="noreferrer">
        <img src="/images/logoWoW.png" alt="World of Warships logo" className={styles.logo} />
      </a>
    </div>
  );
};
export default LogoWoW;

import React from "react";
import styles from "./LogoBabaev.module.css";

const LogoBabaev = () => {
  return (
    <div className={styles.wrapper}>
      <a href="https://babaevskiy.ru/" className={styles.link} target="_blank" rel="noreferrer">
        <img src="/images/logoBabaev.png" alt="Babaev logo" className={styles.logo} />
      </a>
      
    </div>
  );
};
export default LogoBabaev;

import React from "react";
import styles from "./Header.module.css";
import LogoBabaev from "./LogoBabaev";
import LogoTps from "./LogoTps";
import LogoWoW from "./LogoWoW";

const Header = () => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.content}>
        <LogoTps />
        <LogoBabaev />
        <LogoWoW />
      </header>
      <div className={styles.border}></div>
    </div>
  );
};
export default Header;

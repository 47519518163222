import styles from "./AnimationLayer.module.css";

const AnimationLayer = () => {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.branch} ${styles.branch__left}`} />
      <div className={`${styles.branch} ${styles.branch__middle}`} />
      <div className={`${styles.branch} ${styles.branch__behind}`} />
      <div className={`${styles.branch} ${styles.stalker}`} />
      <div className={`${styles.branch} ${styles.branch__right}`} />
    </div>
  );
};

export default AnimationLayer;

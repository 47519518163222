import styles from "./App.module.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Main from "./components/main/Main";
import AnimationLayer from "./components/animation-layer/AnimationLayer";

function App() {
  /* fix viewport units on mobile */
  let vh = window.visualViewport ? window.visualViewport.height * 0.01 : window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.visualViewport ? window.visualViewport.height * 0.01 : window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <div className={styles.wrapper} style={{ backgroundImage: "url('/images/XCt5UTDZzY4SMP4thqFR.jpg')" }}>
      <Header />
      <AnimationLayer />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
